import api from './api'
import forEach from 'lodash/forEach'

export async function getUsersDropdown({ departments = true, users = true } = {}) {
  return new Promise(resolve => {
    api.base
      .get({
        entity: 'employees',
        params: {
          with: {
            employees: ['department', 'user'].join(','),
          },
        },
      })
      .then(employees => {
        employees = employees.items

        let collection = {
          departments: {},
          items: [
            {
              name: 'Не указано',
              value: 'none',
            },
          ],
        }

        employees.forEach(employee => {
          if (employee.department && employee.user) {
            let departmentId = employee.department.id

            if (!collection.departments[departmentId]) {
              collection.departments[departmentId] = Object.assign({}, employee.department, {
                employees: [],
              })
            }

            collection.departments[departmentId].employees.push(employee)
          }
        })

        forEach(collection.departments, department => {
          if (departments) {
            collection.items.push({
              name: department.name,
              value: 'department_' + department.id,
            })
          }

          if (users) {
            department.employees.forEach(employee => {
              collection.items.push({
                name: `${!!departments ? '     ' : ''}${employee['first_name']} ${employee['surname']}`,
                value: 'user_' + employee.user.id,
              })
            })
          }
        })

        resolve(collection.items.slice())
      })
  })
}
