<template>
  <div v-if="data">
    <b>Доходы: {{ data.operations && data.operations.total | formatNumber('0,[00]') }}&#8381;</b>
    <ol v-if="data.operations && data.operations.items" class="pl-15 m-0">
      <li v-for="operation in data.operations.items">
        {{ operation.amount | formatNumber('0,[00]') }}&#8381; ({{ operation.date | formatDate }}) -
        {{ operation.name }}
      </li>
    </ol>
    <br />
    <b>Плановые платежи: {{ data.payments && data.payments.total | formatNumber('0,[00]') }}&#8381;</b>
    <ol v-if="data.payments && data.payments.items" class="pl-15 m-0">
      <li v-for="payment in data.payments.items">
        {{ payment.amount | formatNumber('0,[00]') }}&#8381; ({{ payment.date | formatDate }}) -
        {{ payment.name }}
      </li>
    </ol>
  </div>
</template>

<script>
  export default {
    props: ['data'],
  }
</script>
