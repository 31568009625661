<template>
  <tr :id="'custom_header-' + id">
    <th
      v-for="(col, index) in cols"
      :class="col.class || {}"
      :colspan="col.slice[1]"
      :data-start="col.slice[0]"
      :data-end="col.slice[0] + col.slice[1] - 1"
      :data-is-last="index + 1 === cols.length"
    >
      {{ col.label }}
    </th>
  </tr>
</template>

<script>
  import range from 'lodash/range'
  import difference from 'lodash/difference'
  import forEach from 'lodash/forEach'

  export default {
    name: 'table-header',
    props: ['id', 'cols'],
    data() {
      return {
        table: null,
      }
    },
    computed: {
      tableCols() {
        return parseInt(this.table.getAttribute('aria-colcount'))
      },
      range() {
        return range(1, this.tableCols + 1)
      },
      touchable() {
        let touchable = []

        this.cols.forEach(col => {
          touchable = touchable.concat(range(col.slice[0], col.slice[0] + col.slice[1]))
        })

        return touchable
      },
      untouchable() {
        return difference(this.range, this.touchable)
      },
    },
    mounted() {
      let table = document.querySelector('table')
      this.table = table

      let header = table.querySelector('thead'),
        defaultHeader = header.firstChild

      let customHeader = document.querySelector('#custom_header-' + this.id)

      header.insertBefore(customHeader, defaultHeader)

      this.untouchable.forEach(col => {
        let colNode = defaultHeader.querySelector('[aria-colindex="' + col + '"]')
        colNode.setAttribute('rowspan', 2)
        colNode.classList.add('align-middle')

        forEach(customHeader.childNodes, node => {
          if (node.dataset.start > col) {
            customHeader.insertBefore(colNode, node)
            return false
          } else if (node.dataset.isLast && node.dataset.end < col) {
            customHeader.insertBefore(colNode, node.nextSibling)
            return false
          }
        })
      })
    },
  }
</script>
