<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <template slot="list" slot-scope="data">
      <b-card>
        <custom-header id="report" :cols="customHeader"></custom-header>
        <b-table
          :fields="fields"
          :items="rows"
          :sort-by="data.sort.by"
          :sort-desc="data.sort.desc"
          @sort-changed="$emit('sortChange', $event)"
          :filter="filter"
          no-local-sorting
          responsive
        >
          <template slot="name" slot-scope="data">
            <expander
              :level="data.item.level"
              :has-items="hasItems(data.item)"
              :expanded="data.item._expanded"
              @expand="expand(data.item)"
            >
              <template slot="text">
                <span style="margin-right: 4px" @click="expand(data.item)">
                  &nbsp;&nbsp;{{ data.item.name || 'Без названия' }}
                  <span v-if="data.item.entity" style="opacity: 0.7">({{ data.item.entity.name.toLowerCase() }})</span>
                </span>
                <span v-if="data.item['_links']" v-for="link in data.item['_links']"
                  >| <a :href="link.link" target="_blank">{{ link.name }}</a></span
                >
              </template>
            </expander>
          </template>

          <template slot="index" slot-scope="data"> {{ data.index + 1 }}. </template>

          <template slot="totalSurplus" slot-scope="data">
            {{ (data.item.totalSurplus || 0) | formatNumber('0,[00]') }}
          </template>

          <template slot="totalSurplusPercent" slot-scope="data">
            <template> {{ data.item.totalSurplusPercent | formatNumber('0.00') }}% </template>
          </template>

          <template slot="totalComing" slot-scope="data">
            <template v-if="data.item.totalComing > 0">
              <span class="link green" :id="data.item.id + '_coming_summary'">{{
                (data.item.totalComing || 0) | formatNumber('0,[00]')
              }}</span>
            </template>
            <template v-else>-</template>
          </template>

          <template slot="totalExpense" slot-scope="data">
            <template v-if="data.item.totalExpense > 0">
              <template v-if="data.item.link">
                <a :href="data.item.link" target="_blank" class="link red" :id="data.item.id + '_expense_summary'">{{
                  (data.item.totalExpense || 0) | formatNumber('0,[00]')
                }}</a>
              </template>
              <template v-else>
                <span class="link red" :id="data.item.id + '_expense_summary'">{{
                  (data.item.totalExpense || 0) | formatNumber('0,[00]')
                }}</span>
              </template>
            </template>
            <template v-else>-</template>
          </template>

          <template v-for="date in months" :slot="'in_' + date" slot-scope="data">
            <template v-if="data.item.id !== 'summary' && $dotGet(data.item.dates, [date, 'coming'], 0) > 0">
              <span class="link green" :id="data.item.id + '_coming_' + date">{{
                data.item.dates[date].coming | formatNumber
              }}</span>
              <popover
                :target="data.item.id + '_coming_' + date"
                @show="openPopover($event, data.item, date, 'coming')"
              >
                <in-popup :data="popups.coming[data.item.id + '_coming_' + date]"></in-popup>
              </popover>
            </template>
            <template v-else>-</template>
          </template>

          <template v-for="date in months" :slot="'out_' + date" slot-scope="data">
            <template v-if="data.item.id !== 'summary' && $dotGet(data.item.dates, [date, 'expense'], 0) > 0">
              <a
                :href="data.item.dates[date].link"
                target="_blank"
                class="link red"
                :id="data.item.id + '_expense_' + date"
                >{{ data.item.dates[date].expense | formatNumber }}</a
              >
              <popover
                :target="data.item.id + '_expense_' + date"
                @show="openPopover($event, data.item, date, 'expense')"
              >
                <out-popup :data="popups.expense[data.item.id + '_expense_' + date]"></out-popup>
              </popover>
            </template>
            <template v-else>-</template>
          </template>
        </b-table>
      </b-card>
    </template>
  </list>
</template>

<script>
  import api from '../../../assets/js/api'
  import { getUsersDropdown } from '../../../assets/js/common'
  import moment from 'moment'

  import InPopup from './InPopup'
  import OutPopup from './OutPopup'
  import CustomHeader from '../../../components/TableHeader'
  import List from '../../../components/List'
  import Popover from '../../../components/PopoverAlive'
  import Expander from '../../../components/Expander'

  import entityMixins from '../../../mixins/entity'
  import stairsMixins from '../../../mixins/stairs'

  export default {
    name: 'report',
    components: {
      CustomHeader,
      Expander,
      List,
      Popover,
      InPopup,
      OutPopup,
    },
    mixins: [entityMixins, stairsMixins],
    data() {
      return {
        popups: {
          coming: {},
          expense: {},
        },
        filters: [
          {
            name: 'clientName',
            label: 'Быстрый поиск...',
          },
          {
            name: 'date',
            type: 'date',
            range: true,
          },
          {
            name: 'responsibleManagers',
            label: 'Ответственный',
            type: 'select',
            multiple: true,
            items: [],
          },
        ],
      }
    },
    computed: {
      months() {
        return this.entity.common.months || []
      },

      fields() {
        let fields = [
          {
            key: 'index',
            label: '&nbsp;',
            class: 'text-center w-50',
          },
          {
            key: 'name',
            label: '&nbsp;',
            class: 'text-center',
          },
          {
            key: 'totalSurplus',
            label: 'Сальдо',
            class: 'text-center',
            sortable: true,
          },
          {
            key: 'totalSurplusPercent',
            label: 'Марж-ть',
            class: 'text-center',
            sortable: true,
          },
          {
            key: 'totalComing',
            label: 'Приход',
            class: 'text-center relative',
            sortable: true,
          },
          {
            key: 'totalExpense',
            label: 'Расход',
            class: 'text-center relative',
            sortable: true,
          },
        ]

        this.months.forEach(month => {
          fields.push({
            custom: true,
            key: 'in_' + month,
            label: 'Приход',
            class: 'text-center relative',
            sortable: true,
          })

          fields.push({
            custom: true,
            key: 'out_' + month,
            label: 'Расход',
            class: 'text-center relative',
            sortable: true,
          })
        })

        return fields
      },

      customHeader() {
        let customHeader = [
          {
            label: 'ИТОГО',
            slice: [5, 2],
            class: 'text-center',
          },
        ]

        this.months.forEach((month, key) => {
          customHeader.push({
            custom: true,
            label: moment(month).format('MMMM YY'),
            slice: [5 + 2 * (key + 1), 2],
            class: 'text-center',
            sortable: true,
          })
        })

        return customHeader
      },
    },
    methods: {
      openPopover(e, item, date, type) {
        let id = `${item.id}_${type}_${date}`,
          data = this.popups[type][id]

        if (!data) {
          e.preventDefault()

          api.base
            .get({
              path: 'reports/clients/detailing',
              params: {
                type,

                projectId: item.isProject && item.id,
                clientId: item.isClient && item.id,

                start: moment(date).startOf('month').format('YYYY-MM-DD'),
                end: moment(date).endOf('month').format('YYYY-MM-DD'),

                // Видимо пока не используется
                leadType: null,
              },
            })
            .then(result => {
              this.$set(this.popups[type], id, result.items)
              this.$root.$emit('bv::show::popover', id)
            })
        }
      },

      resultHandler(result) {
        result.items = this.dataPrepare(result)

        this.module.commit('setCommon', {
          months: result._months,
        })
      },
    },
    beforeRouteEnter(to, from, next) {
      getUsersDropdown().then(items => {
        next(vm => {
          let filter = vm.filters.find(f => f.name === 'responsibleManagers')
          filter.items = items
        })
      })
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    .table thead th {
      vertical-align: middle;
    }

    .w-50 {
      width: 50px;
    }

    .relative {
      position: relative;
    }

    .link {
      &.red {
        color: #f30;
        &:hover {
          color: #20a8d8;
        }
      }

      &.green {
        color: #090;
        &:hover {
          color: #20a8d8;
        }
      }

      cursor: default;
      line-height: 1;
      display: inline-block;
    }
  }
</style>
