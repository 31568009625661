import api from '../assets/js/api'
import notification from '../assets/js/notification'
import forEach from 'lodash/forEach'

export default {
  data() {
    return {
      entities: {},
      filter(item) {
        let status = true

        let iterate = item => {
          if (item.parent) {
            if (!item.parent._expanded) {
              status = false
            } else iterate(item.parent)
          }
        }

        iterate(item)

        return status
      },
    }
  },
  computed: {
    rows() {
      let rows = []

      let iterate = items => {
        if (items) {
          forEach(items, item => {
            rows.push(item)
            iterate(item.items)
          })
        }
      }

      iterate(this.items)

      return rows
    },
  },
  methods: {
    extendEntities(entities) {
      entities && (this.entities = Object.assign({}, this.entities, entities))
    },
    hasItems(item) {
      return item['has_items'] || (item.items && !!Object.keys(item.items).length)
    },
    dataPrepare(result, parent) {
      this.extendEntities(result['_entities'])

      let items = result[this.rootKey || 'items']

      let iterate = (items, parent) => {
        if (items) {
          forEach(items, item => {
            parent && (item.parent = parent)
            item.level = (parent && parent.level + 1) || 0

            if (typeof item.visible !== 'undefined' && !item.visible) {
              item._rowVariant = 'not-visible'
            }

            let entity = item['entity_id'] && this.entities[item['entity_id']]
            entity && (item.entity = entity)

            if (typeof this.postPrepare === 'function') {
              this.postPrepare(item)
            }

            iterate(item.items, item)
          })
        }
      }

      iterate(items, parent)

      return items
    },
    getRecursiveParams(item) {
      let params = {}

      let iterate = item => {
        if (item) {
          if (item.entity) {
            let alias = item.entity.alias + '_id'
            params[alias] = params[alias] || item.id
          }
          iterate(item.parent)
        }
      }

      iterate(item)

      return params
    },
    expand(item) {
      if (item.items && Object.keys(item.items).length) {
        this.module.commit('updateItem', {
          source: item,
          merge: true,
          data: {
            _expanded: !item._expanded,
          },
        })
      } else if (item['has_items'] && (item.entity || item.childrenPath)) {
        let params = Object.assign(
          {},
          this.getRecursiveParams(item),
          this.module.getters.query,
          item.childrenParams || {}
        )

        let path

        if (item.entity) {
          path = this.basePath + '/' + item.entity.alias + '-children'
        } else {
          path = this.basePath + '/' + item.childrenPath
        }

        api.base
          .get({
            path: path,
            params: params,
          })
          .then(result => {
            if (result.items && Object.keys(result.items).length) {
              this.module.commit('updateItem', {
                source: item,
                merge: true,
                data: {
                  items: this.dataPrepare(result, item),
                },
              })

              this.expand(item)
            } else {
              this.module.commit('updateItem', {
                source: item,
                merge: true,
                data: {
                  has_items: false,
                },
              })
              notification['warning']('Вложенных элементов нет', 'Внимание')
            }
          })
      }
    },
  },
}
