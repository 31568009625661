<template>
  <div class="expander" :data-level="level" :data-has-items="hasItems">
    <fa class="expand-arrow" :icon="'angle-' + (expanded ? 'up' : 'down')" @click="$emit('expand')"></fa>
    <slot name="text"></slot>
  </div>
</template>

<script>
  export default {
    name: 'expander',
    props: ['level', 'has-items', 'expanded'],
  }
</script>

<style lang="scss" scoped>
  .expander {
    display: flex;
    align-items: center;

    &[data-has-items] {
      cursor: pointer;
      .expand-arrow {
        display: inline-block;
      }
    }

    /deep/ {
      .expand-arrow {
        display: none;
        margin: auto 10px auto 0;
      }
    }

    @for $i from 1 through 10 {
      &[data-level='#{$i}'] {
        padding-left: 15px * $i;
      }
    }
  }
</style>
