export default {
  data() {
    return {
      popoverDelay: {
        show: 100,
        hide: 300,
      },
    }
  },
  methods: {
    savePopover(e) {
      let popover = e.relatedTarget

      let hover =
        popover.querySelector(':hover') ||
        document.querySelector('.tooltip:hover') ||
        e.target.parentElement.querySelector(':hover') === e.target

      if (hover) {
        e.preventDefault()
        let listener = () => {
          setTimeout(() => {
            popover.removeEventListener('mouseleave', listener)
            this.$root.$emit('bv::hide::popover', e.target.id)
          }, 200)
        }
        popover.addEventListener('mouseleave', listener)
      }
    },
  },
}
