<template>
  <div v-if="data" :class="['table-popup', { visible: data.operations }]">
    <template v-if="data.operations && data.operations.items">
      <b>Расходы: {{ data.operations && data.operations.total | formatNumber('0,[00]') }}&#8381;</b>
      <ol class="pl-15 m-0">
        <li v-for="operation in data.operations.items">
          {{ operation.amount | formatNumber('0,[00]') }}&#8381; ({{ operation.date | formatDate }}) -
          {{ operation.name }}
        </li>
      </ol>
    </template>
    <template v-else>
      <b>Расходов нет</b>
    </template>
    <br />
    <template v-if="data.employeeExpense && data.employeeExpense.totalWorth > 0">
      <b
        >Себестоимость: {{ data.employeeExpense.totalWorth | formatNumber('0,[00]') }} &#8381; |
        {{ (data.employeeExpense.totalHours * 3600) | formatDuration }}
      </b>
      <ul class="pl-15 m-0">
        <li v-for="person in data.employeeExpense.items">
          <b>{{ person.name }} {{ person.surname }}</b>
          <p>
            {{ (person.hours * 3600) | formatDuration }} * {{ person.worthPH | formatNumber('0,[00]') }}&#8381; =
            <strong>{{ person.worth | formatNumber('0,[00]') }}&#8381;</strong>
          </p>
        </li>
      </ul>

      <b
        >Средняя себестоимость: {{ data.employeeExpense.totalWorth | formatNumber('0,[00]') }} /
        {{ (data.employeeExpense.totalHours * 3600) | formatDuration }} =
        {{ data.employeeExpense.averageWorth | formatNumber('0,[00]') }}&#8381;
      </b>
    </template>
    <template v-else>
      <b>Время не трекалось</b>
    </template>
  </div>
</template>

<script>
  export default {
    props: ['data'],
  }
</script>
