<template>
  <b-popover
    :target="target"
    :placement="placement"
    :delay="popoverDelay"
    triggers="hover"
    boundary="viewport"
    @hide="savePopover"
    @show="$emit('show', $event)"
  >
    <slot />
  </b-popover>
</template>

<script>
  import popverMixins from '../mixins/popover'

  export default {
    name: 'PopoverAlive',
    mixins: [popverMixins],
    props: {
      target: String,
      placement: {
        type: String,
        default: 'top',
      },
    },
  }
</script>

<style scoped></style>
